<template>
  <div class="auth-wrapper auth-v1 px-2">
    <b-overlay :show="loading" rounded="sm">
      <div class="auth-inner py-2">
        <!-- Login v1 -->
        <b-card class="mb-0 m-0" style="max-width: 100rem">
          <b-link class="brand-logo">
            <vuexy-logo />

            <h2 class="brand-text text-primary">eWork</h2>
          </b-link>

          <b-card-title class="mb-1 text-center">
            Renvoi de code
          </b-card-title>
          <b-card-text class="mb-2">
            Un code de vérification vous sera envoyé à :
            <br />
            <span>
              <strong>{{ registerEmail }}</strong>
            </span>
          </b-card-text>

          <!-- form -->
          <validation-observer ref="codeSend" #default="{ invalid }">
            <b-form class="auth-login-form mt-2" @submit.prevent="returnCode">
              <!-- email -->
              <!-- <b-form-group>
                <ky-input
                  v-model="validate.code"
                  :placeholder="'Code de vérification'"
                  :rules="'required'"
                >
                </ky-input>
                <b-card-text class="mt-1">
                  <b-link :to="{ name: 'login' }">
                    <feather-icon icon='ChevronsRightIcon'></feather-icon>
                   Je n'ai pas reçu le code
                  </b-link>
                </b-card-text>
              </b-form-group> -->
              <!-- submit button -->
              <b-button
                variant="primary"
                type="submit"
                block
                :disabled="invalid"
              >
                Renvoyer le code
              </b-button>
            </b-form>
          </validation-observer>

          <!-- social button -->
        </b-card>
        <!-- /Login v1 -->
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import kyInput from "@/@core/layouts/components/ky-input.vue";
import VuexyLogo from "@/@core/layouts/components/logo.vue";
import {
  BCard,
  BLink,
  BCardText,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
} from "bootstrap-vue";
import { required, code } from "@validations";
import { mapActions, mapState } from "vuex";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    VuexyLogo,
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    ValidationProvider,
    ValidationObserver,
    kyInput,
    ToastificationContent,
  },
  data() {
    return {
      accountVerification: "",
      // validation
      required,
      code,
      validate: {},
      registerEmail: localStorage.getItem("email"),
      errorMessage: "",
      error: "",
      loading: false,
    };
  },

  methods: {
    returnCode() {
      this.$refs.codeSend.validate().then((success) => {
        if (success) {
          this.loading = true;
          this.validate.email = this.registerEmail;
          this.codeReturn(this.$formData(this.validate))
            .then((r) => {
              this.loading = false;
              this.$router.replace("/login-validate");
            })

            .catch((error) => {
              this.loading = false;
              this.errorMessage = error.message;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Code invalid",
                  icon: "AlertCircleIcon",
                  variant: "danger",
                },
              });
            });
        }
      });
    },

    ...mapActions("auth", ["codeReturn"]),
  },
  computed: {
    ...mapState("auth", ["user"]),
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
